<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row">
          <div class="col-md-5">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div class="col-md-5 ml-auto">
            <div class="row">
              <div class="col">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <button class="btn btn-sm btn-primary" @click="addTeam()">
                  Tambah Team
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <v-container>
    
          <v-col>
              <!-- we use this wrapper to show the second scroll above of table -->
              <div id="wrapper2">
                <div id="div2" class="width-scroll" >
                </div>
              </div>
          </v-col>
            
          <v-col>
          <CDataTable
            class="table-striped"
            :items="computedItems"
            :fields="fields"
            style="max-height: 300px; overflow: auto;"
          >
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  @click="edit(item)"
                  class="mr-2"
                  color="warning"
                  square
                  size="sm"
                >
                  Edit
                </CButton>
                
                <CButton
                  v-if="item.id != team.id"
                  @click="hapus(item)"
                  color="danger"
                  square
                  size="sm"
                >
                  Delete
                </CButton>
              </td>
            </template>
          </CDataTable>
          <pagination
            v-if="total !== items.length"
            v-model="page"
            :records="total"
            :per-page="50"
            @paginate="pagination"
          />
          </v-col>
        </v-container>
      </CCardBody>
    </CCard>
    <CModal
      size="lg"
      :title="isUpdate ? 'Edit Team' : 'Tambah Team'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
      :closeOnBackdrop="false"
    >
      <div class="row">
        <div class="col">
          <CInput
            v-model="form.name"
            label="Nama Team"
            placeholder="ketik disini"
          />
          <v-select
            v-model="form.petugas"
            multiple
            placeholder="Pilih Petugas"
            :options="computedPetugas.filter((n) => n)"
          ></v-select>
          <br />
          <v-select
            v-model="form.regions"
            multiple
            placeholder="Pilih Kecamatan"
            :options="computedRegion.filter((n) => n)"
          ></v-select>
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="createModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>
          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah Team
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update Team
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/team"
import firebase from "firebase/compat/app"
import "firebase/auth"
import { uploadImage } from "@/utils/fileUpload";

export default {
  data() {
    return {
      createModal: false,
      fields: data.fields,
      isUpdate: false,
      items: [],
      petugas: [],
      team_region: [],
      team: [],
      page: 1,
      total: 0,
      form: {},
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      isSearching: false,
      searchOn: "",
      modalPassword: false,
      isPasswordValid: null, //null/boolean
      invalidPassword: "",
      verificators: [{ value: 0, label: "-" }],
    }
  },
  methods: {
    selectImage(event) {
      console.log(event);
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.form.profile_picture = resp;  
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    getNameVerificator(id) {
      let data = this.verificators.find((item) => {
        return item.value == id
      })
      if (data) {
        return data.label
      } else {
        return "BUKAN VERIFIKATOR"
      }
    },
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true
        this.getData()
        this.searchOn = this.params.keyword
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!")
      }
    },

    searchOff() {
      this.isSearching = false
      this.params.keyword = ""
      this.getData()
    },
    submit() {
      var loading = this.$loading.show()

      this.form.team_petugas = [];
      this.form.petugas.forEach(element => {
        this.form.team_petugas.push({
          petugas_id: element.value
        })
      });

      this.form.team_regions = [];
      this.form.regions.forEach(element => {
        this.form.team_regions.push({
          region: element.value
        })
      });

      this.$store
        .dispatch("team/addTeam", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan Team")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          console.error(e)
          this.$toast.success(e)
          loading.hide()
        })
    },
    edit(item) {
      this.form = item
      this.isUpdate = true
      this.createModal = true
    },
    update() {
      var loading = this.$loading.show()
      
      this.form.team_petugas = [];

      this.form.petugas.forEach(element => {
        this.form.team_petugas.push({
          petugas_id: element.value
        })
      });

      this.form.team_regions = [];

      this.form.regions.forEach(element => {
        this.form.team_regions.push({
          region: element.value
        })
      });

      this.$store
        .dispatch("team/updateTeam", { id: this.form.id, data: this.form })
        .then(() => {
          this.$toast.success("Berhasil merubah data team")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("team/deleteTeam", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data team")
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      }
    },
    getData() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("team/getTeam", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total
          console.log(this.item)

          // khusus untuk checkbox
          this.selectedItems = []
          this.items.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
            }
          })
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataUser() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      }

      this.$store
        .dispatch("user/getUser", params)
        .then((resp) => {
          this.petugas = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataRegion() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      }

      this.$store
        .dispatch("kecamatan/getKecamatan", params)
        .then((resp) => {
          this.team_region = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    addTeam() {
      this.isUpdate = false
      this.form = {}
      this.createModal = true
    },
    pagination(page) {
      this.page = page
      this.params.page = page
      this.getData()
    },
    deleteAccountByTeamId(teamId) {
      firebase
        .auth()
        .getTeam(teamId)
        .then((teamRecord) => {
          // Hapus akun pengguna dengan metode delete()
          return firebase.auth().deleteTeam(teamRecord.uid)
        })
        .then(() => {
          // Akun berhasil dihapus
          console.log("Akun berhasil dihapus berdasarkan ID pengguna:", teamId)
        })
        .catch((error) => {
          // Tangani kesalahan jika ada
          console.error("Error saat menghapus akun:", error.message)
        })
    },
  },
  computed: {
    computedPetugas() {
      return this.petugas.map((item) => {
        if (item.type == 2)
          return {
            value: item.id,
            label: item.full_name,
          };
      });
    },
    computedRegion() {
      return this.team_region.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
      });
    },
    computedItems() {
      return this.items.map((item, index) => {

        let _petugas = '';
        item.petugas.forEach(element => {
          _petugas += element.full_name + ',';
        });

        let _kecamatan = '';
        item.regions.forEach(element => {
          _kecamatan += element.name + ',';
        });

        return {
          ...item,
          role: item.petugas ? item.petugas.full_name : "Tidak ada",
          pd: item.regions ? item.regions.name : "-",
          petugas: item.petugas.map((e) => {
            return {
              value: e.id,
              label: e.full_name,
            };
          }),
          petugas_name: _petugas,
          regions: item.regions.map((e) => {
            return {
              value: e.id,
              label: e.name,
            };
          }),
          regions_name: _kecamatan,
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
        }
      })
    },
  },
  mounted() {
    this.getData()
    this.getDataUser()
    this.getDataRegion()

    let wrapper_1 = document.querySelector('.table-striped .table-responsive');
    let wrapper_2 = document.querySelector('#wrapper2');
    wrapper_1.onscroll = function() {
      wrapper_2.scrollLeft = wrapper_1.scrollLeft;
    };
      
    wrapper_2.onscroll = function() {
      wrapper_1.scrollLeft = wrapper_2.scrollLeft;
    };
  },
}
</script>

<style>

/* This is the size of the path of the scrollbar */
#wrapper2{
      width: 100%;
      overflow-x: scroll; 
      overflow-y:hidden;
  }

/* This div allow to make the scroll function and show the scrollbar */
  #div2 {
      height: 1px;
      overflow: scroll;
  }

  /* This is the size of the scrollbar */
  .width-scroll{
    width: 1500px;
  }

  @media only screen and (max-width: 767px) {

  /* Adjust styles for mobile display here */
  #wrapper2 {
    width: 100%;
    overflow-x: auto;
  }

  #div2 {
    height: 7px;
    overflow: scroll;
  }

  .width-scroll {
    width: 1500px;
  }

  }

</style>